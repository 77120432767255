import React, { useEffect, useState } from "react";
import "./DashBoard.css";
import Layout from "../../components/Layout/Layout";
import { Button, Col, Image, Row } from "react-bootstrap";
import { getGraphInfo, getStockInfo } from "../../API/HomeAPI/HomeAPI";
import { LineChart } from "@mui/x-charts";
import { Backdrop, CircularProgress, Radio } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { BsArrowUpRight } from "react-icons/bs";
import { BsArrowDownLeft } from "react-icons/bs";

import { IC_DAILYEUSERS, IC_MONTHLYUSERS, IC_NEWUSERS, IC_TOTALUSERSUSERS } from "../../constants/images";

const recordCountSelecter = [10, 15, 20, 25, 30];

function DashBoard(props) {
  const [stockData, setStockData] = useState({});
  const [graphData, setGraphData] = useState({});
  const [year, setYear] = useState(dayjs());
  const [month, setMonth] = useState(dayjs(year));
  const [selectMonth, setSelectMonth] = useState(false);
  const [loading, setLoading] = useState(true);
  const[userGrowthData,setUsersGrowthData] = useState({});
  const [revenueData,setRevenueData] = useState({});
  const [reportData, setReportData] = useState({})
  const [pageCount, setPageCount] = useState(1);
  const [recordCount, setRecordCount] = useState(recordCountSelecter[0]);
  const [period, setPeriod] = useState("7");
  const [startDate, setStartDate] = useState(dayjs("2024-04-01"));
  const [endDate, setEndDate] = useState(dayjs(new Date()));
  const [currencyType, setCurrencyType] = useState('KARATS');
  const windowHeight = window.innerHeight;

  useEffect(() => {
    
    const token = sessionStorage.getItem("token");
    getStockInfo(token,period,startDate,endDate).then((res) => {
      setStockData(res.data);
      setRevenueData(res?.data?.revenueStreams);
      setUsersGrowthData(res?.data?.userBaseGrowth);
      setLoading(false);
    }).catch((err) => {
      console.log(err);
      setLoading(false);
    })
  }, [period,startDate,endDate,loading])
  console.log(dayjs("2024"));

  useEffect(() => {
    // setGraphData({});
    const token = sessionStorage.getItem("token");
    // getGraphInfo(token, year.year(), selectMonth ? month.month() + 1 : "",currencyType).then((res) => {
    //   setGraphData(res.data);
    // }).catch((err) => {
    //   console.log(err);
    //   setLoading(false);
    // }).finally(() => {
    //   setLoading(false);
    // })

    getGraphInfo(token, year.year(), selectMonth ? month.month() + 1 : "",currencyType).then((res) => {
      setGraphData(res.data);
      setLoading(false);
    }).catch((err) => {
      console.log(err);
      setLoading(false);
    }).finally(() => {
      setLoading(false);
    })


  }, [selectMonth, year, month,currencyType,loading])

  const monthsLabels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  return (
    <Layout >
      {/* filters */}
      <Row style={{ margin: "1.5vw " }}>
        <div style={{ marginTop: "2.5vh" }}>
          <Row
            style={{
              display: "flex",
              alignItems: "flex-end",
              marginBottom: "1.5%",
            }}
          >
            <Col xs={5}>
              {["7", "30", "60", "Lifetime"].map((value) => {
                return (
                  <Button
                    style={{ borderRadius: 15, margin: "0 10px" }}
                    variant="outline-danger"
                    active={period === value}
                    onClick={() => {
                      setPageCount(1);
                      setPeriod(value);
                      setLoading(true);
                    }}
                    disabled={loading}
                  >
                    {value === "Lifetime" ? value : `Last ${value} Days`}
                  </Button>
                );
              })}
            </Col >
            <Col style={{ display: "flex", justifyContent: "flex-end" }} xs={6}>
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(newValue) => {
                      setPeriod("");
                      setPageCount(1);
                      setStartDate(newValue);
                      setLoading(true);
                    }}
                    minDate={dayjs("2024-04-01")}
                    maxDate={dayjs(new Date())}
                    disabled={loading}
                  />
                </LocalizationProvider>
              </div>
              <div style={{ marginLeft: "1%" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="End Date"
                    value={endDate}
                    onChange={(newValue) => {
                      setPeriod("");
                      setPageCount(1);
                      setEndDate(newValue);
                      setLoading(true);
                    }}
                    minDate={dayjs("2024-04-01")}
                    maxDate={dayjs(new Date())}
                    disabled={loading}
                  />
                </LocalizationProvider>
              </div>
            </Col>
          </Row>
        </div>
      </Row>
 {/* filters Ends*/}
          <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={loading}
            // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
      {/* user base growth */}
      <Row style={{ margin: "0 1.5vw" }}>
        <h1 className="DashSideHeadingSty">
          User Base Growth
        </h1>
      </Row>
      <Row style={{ margin: "1.5vw", borderRadius: 20, border: "1px solid black", padding: '15px 0' }}>

        <Col style={{
          borderRight: "1px solid black",
          height: "100%",
          margin: "0 1vw"
        }}>
          <DashLabelData label={"Total Users"} value={userGrowthData?.totalUsers} image={IC_TOTALUSERSUSERS} />
        </Col>

        <Col style={{
          borderRight: "1px solid black",
          height: "100%",
          margin: "0 1vw"
        }}>
          <DashLabelData label={"Daily Active Users"} value={userGrowthData?.dailyActiveUsers} image={IC_DAILYEUSERS} />
        </Col>

        <Col style={{
          borderRight: "1px solid black",
          height: "100%",
          margin: "0 1vw"
        }} >
          <DashLabelData label={"Monthly Active Users"} value={userGrowthData?.monthlyActiveUsers} image={IC_MONTHLYUSERS} />
        </Col>

        <Col >
          <DashLabelData label={"New Users"} value={userGrowthData?.newUsers} image={IC_NEWUSERS} />
        </Col>

      </Row>
      {/* user base growth ends*/}

      {/* Revenue streams */}
      <Row style={{ margin: "0 1.5vw" }}>
        <h5 className="DashSideHeadingSty">
          Revenue Streams
        </h5>
      </Row>
      <Row style={{ margin: "0 1.5vw", borderRadius: 20, border: "1px solid black" }}>
        <Row style={{ margin: "0", borderRadius: 20, border: "1px solid black", padding: '15px 0' }}>
          <Col style={{
            borderRight: "1px solid black",
            height: "100%",
            margin: "0 1vw"
          }}>
            <DashLabelData label={"Commissions Collected"} value={revenueData?.commissionCollected}  user={true} isAmount={true}/>
          </Col>

          <Col style={{
            borderRight: "1px solid black",
            height: "100%",
            margin: "0 1vw"
          }}>
            <DashLabelData label={"Advertising Revenue"} value={revenueData?.advertisingRevenue} user={true}  isAmount={true}/>
          </Col>

          <Col style={{
            borderRight: "1px solid black",
            height: "100%",
            margin: "0 1vw"
          }} >
            <DashLabelData label={"Rabbet Coins Revenue"} value={revenueData?.rabbetCoinsRevenue} user={true}  isAmount={true}/>
          </Col>

          <Col >
            <DashLabelData label={"Rabbet Karat Bundle Revenue"} value={revenueData?.rabbetKaratBundleRevenue} user={true}  isAmount={true}/>
          </Col>

        </Row>
        <Row style={{ margin: "0", padding: '15px 0' }}>
          <Col style={{
            borderRight: "1px solid black",
            height: "100%",
            margin: "0 1vw"
          }}>
            <Row style={{ margin: "1.5vw 0" }}>
              <h5 className="DashHeadingSty">Commission Breakdown</h5>
              <Row >

                <LabelValueRow label="Commissions Collected (#):" value={revenueData?.commissionCollected?.count ??0} />
              </Row>
              <Row>

                <LabelValueRow label="Commissions Collected ($):" value={'$ '+revenueData?.commissionCollected?.amount ??0} />
              </Row>
              <Row>

                <LabelValueRow label="Avg. Commissions Collected ($):" value={revenueData?.commissionCollected?.average ??0} />
              </Row>
            </Row>

            <Row style={{ margin: "1.5vw 0" }}>
              <h5 className="DashHeadingSty">Redemption Requests</h5>
              <Row>

                <LabelValueRow label="Total Requests (#):" value={revenueData?.redemptionRequests?.count ??0} />
              </Row>
              <Row>

                <LabelValueRow label="Total Request Value ($):" value={'$ '+revenueData?.redemptionRequests?.amount ??0} />
              </Row>
              <Row>

                <LabelValueRow label="Avg. Request Amount ($):" value={revenueData?.redemptionRequests?.average ??0} />
              </Row>
            </Row>
          </Col>

          <Col>
            <Row style={{ margin: "1.5vw 0" }}>
              <h5 className="DashHeadingSty">Rabbet Coins Breakdown</h5>
              <Row>

                <LabelValueRow label="Total Purchased (# Deposit):" value={revenueData?.rabbetCoinsRevenue?.count ??0} />
              </Row>
              <Row>

                <LabelValueRow label="Total Collected ($):" value={'$ '+revenueData?.rabbetCoinsRevenue?.amount ??0} />
              </Row>
              <Row>

                <LabelValueRow label="Avg.Bundle (Deposit) Value ($):" value={revenueData?.rabbetCoinsRevenue?.average ??0} />
              </Row>
            </Row>

            <Row style={{ margin: "1.5vw 0" }}>
              <h5 className="DashHeadingSty">Rabbet Bundle Breakdown</h5>
              <Row>

                <LabelValueRow label="Bundles Purchased (#):" value={revenueData?.rabbetKaratBundleRevenue?.count ??0} />
              </Row>
              <Row>

                <LabelValueRow label="Total Collected ($):" value={'$ '+revenueData?.rabbetKaratBundleRevenue?.amount ??0} />
              </Row>
              <Row>

                <LabelValueRow label="Avg. Bundle (Deposit) Value ($):" value={revenueData?.rabbetKaratBundleRevenue?.average ??0} />
              </Row>
            </Row>
          </Col>
        </Row>
      </Row>
      {/* Revenue streams Ends */}

  {/* Posted Offers */}

      <Row style={{ margin: "1vw " }}>
        <Col>
          <div className="graph-cont">
            <Row>
              <Col xs={3}>
                <h1 className="sideHeadingSty" style={{ margin: "0 0 1.5vh" }}> Posted Offers </h1>
              </Col>
              <Col xs={9} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", paddingBottom: "1.5vh" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs} >
                  <DatePicker
                    value={year}
                    onChange={(value) => { setYear(value ?? dayjs()) }}
                    minDate={dayjs("2024")}
                    maxDate={dayjs()}
                    openTo="year"
                    views={['year']}
                    className="yearDatePicker"
                  />

                </LocalizationProvider>

                <Row style={{ marginLeft: "1%" }}>
                  <Col xs={5} >
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                      <DatePicker
                        disabled={!selectMonth}
                        value={month}
                        onChange={(value) => { setMonth(value ?? dayjs()); }}
                        maxDate={dayjs()}
                        openTo="month"
                        views={['month']}
                        className={"monthDatePicker " + (!selectMonth ? "monthDatePickerInActive" : "")}

                      />
                    </LocalizationProvider>
                  </Col>
                  <Col xs={1} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Radio checked={selectMonth} label="Solid" variant="solid" onClick={() => { setSelectMonth(prev => !prev) }} color="warning" style={{ backgroundColor: "white" }} />
                  </Col>
                  <Col xs={6}>
                      {["COINS",'KARATS'].map((value) => {
                        return (
                          <Button
                            style={{ borderRadius: 15, margin: "0 10px" ,backgroundColor:currencyType !== value && "transparent",color:currencyType !== value && "black"}}
                            variant={value === "KARATS" ? "success":"warning" }
                            active={currencyType === value }
                            
                            onClick={() => {
                              setCurrencyType(value);
                            }}
                            disabled={loading}
                            
                          >
                            {value}
                          </Button>
                        );
                      })}
                  </Col>
                </Row>

              </Col>

            </Row>
            <div className="graph-sub">
              <LineChart
                loading={loading}
                series={[
                  { data: graphData?.marketplaceOffers ?? [], label: 'marketplaceOffers' },
                  { data: graphData?.directOffers ?? [], label: 'directOffers' },
                  // { data: PromoOfferData, label: 'PromoOfferData' },
                ]}
                xAxis={[{ scaleType: 'point', data: !selectMonth && graphData?.TYPE === "Year" ? monthsLabels : getDaysInMonth(year.year(), month.month()) }]}
                height={windowHeight / 3}
                grid={{ horizontal: true }}
              />

            </div>

          </div>
        </Col>
        <Col xs={4}>
          <div className="graph-cont">
            <h5 className="DashHeadingSty" style={{ textAlign: 'center' }}>Marketplace Offers</h5>
            <LabelValueRow label="Total Offers (#):" value={graphData?.marketplace?.totalOffers ??0} />
            <LabelValueRow label="Total Offered ($):" value={`$ ${(parseFloat(graphData?.marketplace?.totalOffered$ ?? 0) || 0).toFixed(2)}`} />
            <LabelValueRow label="Total Accepted (#):" value={graphData?.marketplace?.totalAccepted ??0} />
            <LabelValueRow label="Total Accepted ($):" value={`$ ${(parseFloat(graphData?.marketplace?.totalAccepted$ ?? 0) || 0).toFixed(2)}`} />

            <h5 className="DashHeadingSty" style={{ textAlign: 'center' }}>Direct Offers</h5>
            <LabelValueRow label="Total Offers (#):" value={graphData?.direct?.totalOffers ??0} />
            <LabelValueRow label="Total Offered ($):" value={`$ ${(parseFloat(graphData?.direct?.totalOffered$ ?? 0) || 0).toFixed(2)}`} />
            <LabelValueRow label="Total Accepted (#):" value={graphData?.direct?.totalAccepted ??0} />
            <LabelValueRow label="Total Accepted ($):" value={`$ ${(parseFloat(graphData?.direct?.totalAccepted$ ?? 0) || 0).toFixed(2)}`} />
          </div>
        </Col>
      </Row>
  {/* Posted Offers  Ends*/}
    </Layout>
  );
}

export default DashBoard;



const LabelData = ({ label, value }) => {
  return (
    <div className="LabelData-container">
      <h1 className="LabelData-label">{label}</h1>
      {label === "Total Users" ? (<>
        <p className="LabelData-value"><input className="value-cont" disabled value={value} /></p>
        <p className="LabelData-value" style={{ visibility: "hidden" }}> s<span className="value-cont"></span></p>
      </>


      ) : (
        <>
          <p className="LabelData-value"><span>Coins:</span> <input className="value-cont" disabled value={value?.coins} /></p>
          <p className="LabelData-value"><span>Karats:</span> <input className="value-cont" disabled value={value?.karats} /></p>
        </>
      )}
    </div>
  );
};

const DashLabelData = ({ label, value, image,user,isAmount =false }) => {
  return (
    <div className="DashlabelData-container">
      <Row>
        <Col xs={8} style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}>
          <div >
            <h2 className="DashlabelData-value">{user ? '$':''} {isAmount ? value?.amount : value?.count }</h2>
          </div>
        </Col>
        {
          image && (
            <Col xs={4} style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
              <Image src={image} rounded height={54} width={54} style={{ alignItems: 'center', justifyContent: 'center' }} />
            </Col>
          )
        }

      </Row>
      <Row>
        <h3 className="DashlabelData-label">{label}</h3>
      </Row>
      <Row>
        <Col xs={4} style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap:10
            }}> 
            {value?.growth?.startsWith('-') ? (
    <BsArrowDownLeft color="red" size={12} />
  ) : (
    <BsArrowUpRight color="green" size={12} />
  )}
            <p className="DashlabelData-percent">{value?.growth}</p>
            </Col>
        <Col xs={8}><p className="DashlabelData-percent">{value?.percentageGrowth}% this week</p></Col>
      </Row>
    </div>
  );
};

const LabelValueRow = ({ label, value }) => {
  return (
    <Row>
      <Col xs={8}>
        <p>{label}</p>
      </Col>
      <Col xs={4}>
        <p>{value}</p>
      </Col>
    </Row>
  );
};

function getDaysInMonth(year, month) {
  if (month === undefined) {
    month = 0;
  }
  const date = new Date(year, month + 1, 0);
  const daysInMonth = date.getDate();

  const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
  if (days.length <= 28) {
    days.push("-", "-", "-");
  }
  else if (days.length <= 29) {
    days.push("-", "-");
  }
  else if (days.length <= 30) {
    days.push("-");
  }
  return days
}