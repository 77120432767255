import axios from "axios";
import config from "../../constants/config";

export const getUsersData = async (token) => {
    console.log('called create Promo Method');
    const url1 = config.API_URL + "/admin/users"
    return await axios.get(url1, {
      headers: {
        Authorization: token, // Correct placement of Authorization header
        'Content-Type': 'application/json', // Ensure content type is set to JSON
      },
    });
  };



export const getAdminData = async (token) => {
    console.log('called create Promo Method');
    const url1 = config.API_URL + "/user/userDetail"
    return await axios.get(url1, {
      headers: {
        Authorization: token, // Correct placement of Authorization header
        'Content-Type': 'application/json', // Ensure content type is set to JSON
      },
    });
  };


  
export const getStockInfo = async (token,period,startDate,endDate) => {
  console.log('called create Promo Method');
  const url1 = config.API_URL + "/admin/stockInfo"
  return await axios.get(url1, {
    headers: {
      Authorization: token, // Correct placement of Authorization header
      'Content-Type': 'application/json', // Ensure content type is set to JSON
    },
    params:{
      period,
      startDate,
      endDate
    }
  });
};


  
export const getGraphInfo = async (token,year,month,currencyType) => {
  console.log('called create Promo Method');
  const url1 = config.API_URL + "/admin/offersGraph"
  return await axios.get(url1, {
    headers: {
      Authorization: token, // Correct placement of Authorization header
      'Content-Type': 'application/json', // Ensure content type is set to JSON
    },
    params:{
      year,
      month,
      currencyType
    }
  });
};