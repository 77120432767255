import React, { useEffect, useState } from "react";
import "./Advertising.css";
import Layout from "../../components/Layout/Layout";
import {
  createAdvertisement,
  deleteAdvertisement,
  getAdvertisements,
  updateAdvertisement,
} from "../../API/AdvertisementsAPI/AdvertisementsAPI";
import { Button, Col, Form, Image, Modal, Row } from "react-bootstrap";
import JsonView from "react18-json-view";
import { Backdrop, CircularProgress, Radio, TextField } from "@mui/material";
import LeagueInput from "../../components/RInput/LeagueInput";
import { IC_DAILYEUSERS, IC_NEWUSERS } from "../../constants/images";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
function Advertising(props) {
  const [selectedCard, setSelectedCard] = useState(null);
  const [availableLocations, setAvailableLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [advCards, setAdvCards] = useState([]);
  const [loading, setIsloading] = useState(false);
  const [showAdModal, setShowAdModal] = useState(false);
  const [adImage, setAdImage] = useState();
  const [isSaving] = useState(false);
  const [adLink, setAdLink] = useState("");
  const [regionalPriority, setRegionalPriority] = useState(0);
  const [adRegion, setAdRegion] = useState("");
  const [adWithinAppRouting, setAdWithinAppRouting] = useState("");
  const [adOutsideAppRouting, setAdOutsideAppRouting] = useState("");
  const [adType, setAdType] = useState("Ad");
  const [refresh, setRefresh] = useState(true);

  const handleShowModal = (card = null) => {
    if (card) {
      console.log(card, "CardDetails");
      // Editing existing ad
      setSelectedCard(card);
      setAdLink(card.link);
      setAdType(card.type);
      setAdRegion(card.region);
      setAdWithinAppRouting(card.withinAppRouting);
      setAdOutsideAppRouting(card.outsideAppRouting);
      setRegionalPriority(card.regionalPriority);
      setAdImage(card.imageUrl);
      setIsEditMode(true); // Set mode to editing
    } else {
      // Creating new ad
      setSelectedCard(null);
      setAdLink("");
      setAdType("Ad");
      setAdRegion("");
      setAdWithinAppRouting("");
      setAdOutsideAppRouting("");
      setRegionalPriority("");
      setAdImage(IC_NEWUSERS);
      setIsEditMode(false); // Set mode to creating
    }
    setShowAdModal(true); // Show the modal
  };

  const handleshowModalClose = () => {
    setShowAdModal(false);
    setAdImage("");
  };

  const createAdv = () => {
    if (
      (adImage,
      adType,
      adOutsideAppRouting,
      adWithinAppRouting,
      adRegion,
      adLink)
    ) {
      const token = sessionStorage.getItem("token");
      createAdvertisement(
        token,
        adImage,
        adType,
        adOutsideAppRouting,
        adWithinAppRouting,
        adRegion,
        regionalPriority,
        adLink
      )
        .then((res) => {
          setShowAdModal(false);
          setRefresh((res) => !res);
          console.log(res?.data);
          alert("Advertisement created Successfully");
        })
        .catch((err) => {
          console.log(err);
          //setShowAdModal(false);
          alert("Advertisement not created, please try again!");
        });
    } else {
      alert("please fill all the fields");
    }
  };

  const updateAdv = () => {
    if (selectedCard) {
      const token = sessionStorage.getItem("token");
      updateAdvertisement(
        token,
        selectedCard?._id,
        adImage,
        adType,
        adOutsideAppRouting,
        adWithinAppRouting,
        adRegion,
        regionalPriority,
        adLink
      )
        .then((res) => {
          setShowAdModal(false);
          setRefresh((res) => !res);
          console.log(res?.data, adRegion);
          alert("Advertisement Updated");
        })
        .catch((err) => {
          console.log(err);
          //setShowAdModal(false);
          alert("Advertisement not Updated, please try again!");
        });
    } else {
      alert("please fill all the fields");
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log(reader.result);
        setAdImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      alert("Please select an image file.");
    }
  };
  const handleSelectionChange = (newSelection) => {
    setSelectedLocations(newSelection);
    console.log("Selected states:", newSelection);
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    setIsloading(true);
    if (token) {
      console.log("Token retrieved:", token);

      getAdvertisements(token)
        .then((res) => {
          console.log(res.data);
          // setAdvCards(res?.data);
          setAdvCards(res.data?.advCards);
          setAvailableLocations(res?.data?.availableLocations);
          setSelectedCard(res.data[0]);
          setIsloading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsloading(false);
        });
    } else {
      console.log("Token Not Found");
      // Redirect to login page or display a message
      setIsloading(false);
    }
  }, [refresh]);
  return (
    <Layout>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "3%" }}
      >
        <Button
          variant="primary"
          onClick={() => {
            handleShowModal();
          }}
        >
          Add new
        </Button>
      </div>
      <Modal show={showAdModal} onHide={handleshowModalClose} fullscreen={true}>
        <Modal.Header>
          <Modal.Title>
            {isEditMode ? "Update Advertisement" : "Create Advertisement"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <AdModalCard
          title={isEditMode ? `Update Ad for ${adRegion || ""}` : "Create New Ad"}
          previewImage={adImage}
          adImage={adImage}
          setAdImage={setAdImage}
          isActive={true} // You can make it dynamic if needed
          regionalPriority={regionalPriority}
          setRegionalPriority={setRegionalPriority}
          adLink={adLink}
          setAdLink={setAdLink}
          adRegion={adRegion}
          setAdRegion={setAdRegion}
          handleSelectionChange={handleSelectionChange}
          availableLocations={availableLocations} // Populate if you have data
          selectedLocations={selectedLocations}
       //   setSelectedStates={setSelectedStates} // Populate if you have data
          adWithinAppRouting={adWithinAppRouting}
          setAdWithinAppRouting={setAdWithinAppRouting}
          adOutsideAppRouting={adOutsideAppRouting}
          setAdOutsideAppRouting={setAdOutsideAppRouting}
          adType={adType}
          setAdType={setAdType}
          handleFileChange={handleFileChange}
          isEditMode={isEditMode}
          isSaving={isSaving}
          states={availableLocations}
          createAdv={createAdv}
          updateAdv={updateAdv}
        /> */}

          <AdCard
            cardData={{}}
            availableLocations={availableLocations}
            setRefresh={setRefresh}
            isCreateCard={true}
            setShowAdModal={setShowAdModal}
            handleshowModalClose={handleshowModalClose}
          />
        </Modal.Body>

      </Modal>
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={loading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div>
        {advCards.map((adData, index) => (
          <AdCard
            id={adData._id}
            title={`Ad Details for ${adData.region}`}
            cardData={adData}
            availableLocations={availableLocations}
            setRefresh={setRefresh}
            key={index}
          />
        ))}
      </div>
    </Layout>
  );
}

export default Advertising;

const CheckboxSelection = ({ states, onSelectionChange, selectedStates }) => {
  const [checkedStates, setSelectedStates] = useState(selectedStates);

  const handleCheckboxChange = (state) => {
    setSelectedStates((prevSelected) => {
      const updatedSelection = prevSelected.includes(state)
        ? prevSelected.filter((item) => item !== state)
        : [...prevSelected, state];
      // Notify parent component of the change
      onSelectionChange(updatedSelection);
      return updatedSelection;
    });
  };

  return (
    <div
      style={{
        height: "160px", // Set height for the scrollable container
        // border: '1px solid #ddd',
        // borderRadius: '8px',
        // overflowY: 'auto',
        padding: "10px",
        backgroundColor: "#f9f9f9",
      }}
    >
      {states.map((state) => (
        <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
          <div key={state} style={{ marginBottom: "8px" }}>
            <li
              style={{
                padding: "5px 10px",
                marginBottom: "5px",
                borderRadius: "4px",
                backgroundColor: "#ffffff",
                border: "1px solid #ccc",
              }}
            >
              <input
                type="checkbox"
                checked={checkedStates.includes(state)}
                onChange={() => handleCheckboxChange(state)}
              />
              <span style={{ marginLeft: "10px" }}>{state}</span>
            </li>
          </div>
        </ul>
      ))}
    </div>
  );
};

const AdCard = ({
  id,
  title,
  cardData,
  availableLocations,
  setRefresh,
  isCreateCard = false,
  setShowAdModal,
  handleshowModalClose
}) => {
  const [selectedStates, setSelectedStates] = useState(
    cardData?.selectedLocations ? cardData?.selectedLocations : []
  );
  const [isActiveCard, setIsActiveCard] = useState(isCreateCard ? true:cardData?.isActive);

  const [adImage, setAdImage] = useState(cardData?.imageUrl ?? "" );
  const [isSaving, setIsSaving] = useState(false);
  const [routeType, setRouteType] = useState(cardData?.routeType ?? "");
  const [regionalPriority, setRegionalPriority] = useState(
    cardData?.regionalPriority ?? 0
  );
  const [adRegion, setAdRegion] = useState(cardData?.region ?? "");
  const [adWithinAppRouting, setAdWithinAppRouting] = useState(
    cardData?.withinAppRouting ?? ""
  );
  const [adOutsideAppRouting, setAdOutsideAppRouting] = useState(
    cardData?.outsideAppRouting ?? ""
  );
  const [adType, setAdType] = useState(cardData?.type ?? "");

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log(reader.result);
        setAdImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      alert("Please select an image file.");
    }
  };

  const deleteCard = (id) => {
    const token = sessionStorage.getItem("token");
    alert("Proceed to delete the Advertisement!");
    deleteAdvertisement(token, id)
      .then((res) => {
        console.log(res.data);
        setRefresh((res) => !res);
      })
      .catch((err) => {
        console.log(err);
        //setShowAdModal(false);
        alert("Advertisement not Deleted, please try again!");
      });
  };

  const updateAdv = () => {
    const token = sessionStorage.getItem("token");
    setIsSaving(true);
    updateAdvertisement(
      token,
      id,
      adImage,
      adType,
      adOutsideAppRouting,
      adWithinAppRouting,
      adRegion,
      regionalPriority,
      routeType,
      isActiveCard,
      selectedStates
    )
      .then((res) => {
        setRefresh((res) => !res);

        setIsSaving(false);
        console.log(res?.data, adRegion);
        alert("Advertisement Updated");
      })
      .catch((err) => {
        console.log(
          err?.response?.data?.error?.name,
          err?.response?.data?.error?.message
        );
        //setShowAdModal(false);
        alert(err?.response?.data?.error?.message);
        setIsSaving(false);
      });
  };

  const createAdv = () => {
    if (
      ( adImage,
        adType,
        adRegion,
        regionalPriority,
        routeType,
        isActiveCard)
    ) {
      const token = sessionStorage.getItem("token");
      createAdvertisement(
        token,
        adImage,
        adType,
        adOutsideAppRouting,
        adWithinAppRouting,
        adRegion,
        regionalPriority,
        routeType,
        isActiveCard,
        selectedStates
      )
        .then((res) => {
          setShowAdModal(false);
          setRefresh((res) => !res);
          console.log(res?.data);
          alert("Advertisement created Successfully");
        })
        .catch((err) => {
          console.log(err);
          //setShowAdModal(false);
          alert("Advertisement not created, please try again!");
        });
    } else {
      alert("please fill all the fields");
    }
  };

  

  return (
    <div
      style={{
        borderRadius: "10px",
        border: "1px solid #000",
        margin: "1.5vw 1vw",
      }}
    >
      <Row style={{ padding: 25 }}>
        <Col md={6} style={{ textAlign: "left" }}>
          <Row>
            <h5 style={{ textAlign: "center" }}>{title}</h5>
          </Row>
          <Row>
            <p>Preview</p>
            <Col xs={6}>
              <img
                src={adImage}
                style={{
                  width: "100%",
                  height: 180,
                  resize: "both",
                  borderRadius: 10,
                }}
              />
            </Col>
            <Col md={5}>
              <h6>Active/Show In App</h6>

              <Radio
                checked={isActiveCard}
                label="Solid"
                variant="solid"
                onClick={() => {
                  setIsActiveCard(!isActiveCard);
                }}
                color="success"
                style={{
                  backgroundColor: "white",
                  border: "2px solid #25750d",
                }}
              />
              <Radio
                checked={!isActiveCard}
                label="Solid"
                variant="solid"
                onClick={() => {
                  setIsActiveCard(!isActiveCard);
                }}
                color="warning"
                style={{ backgroundColor: "white" }}
              />

              <LeagueInput
                lable="Order"
                value={regionalPriority}
                setInput={setRegionalPriority}
                type="number"
              />
            </Col>
          </Row>

          <div>
            <Form.Group controlId="formFile" className="mt-4">
              <Form.Label>Image Upload</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={handleFileChange}
              />
            </Form.Group>
          </div>
          <LeagueInput lable="Link" value={routeType} setInput={setRouteType} />
          <LeagueInput lable="Region" value={adRegion} setInput={setAdRegion} />
        </Col>

        <Col md={6} style={{ textAlign: "left" }}>
          <Row>
            <h5 style={{ textAlign: "center" }}>Location Exclusions</h5>
          </Row>
          <Row>
            <Col xs={5}>
              <h6>Available</h6>
              <div
                style={{
                  height: 160,
                  border: "1px solid #000",
                  borderRadius: "10px",
                  overflow: "auto",
                }}
              >
                <CheckboxSelection
                  states={availableLocations}
                  onSelectionChange={setSelectedStates}
                  selectedStates={selectedStates}
                />
              </div>
            </Col>
            <Col
              xs={1}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: 160,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 5,
                }}
              >
                <Row
                  style={{
                    border: "1px solid #718EBF",
                    borderRadius: "10px",
                    height: 44,
                    width: 44,
                    alignItems: "center",
                  }}
                >
                  <BsArrowRight size={20} color="#718EBF" />
                </Row>
                <Row
                  style={{
                    border: "1px solid #718EBF",
                    borderRadius: "10px",
                    height: 44,
                    width: 44,
                    alignItems: "center",
                  }}
                >
                  <BsArrowLeft size={20} color="#718EBF" />
                </Row>
              </div>
            </Col>
            <Col xs={5}>
              <h6>Selected</h6>
              <div
                style={{
                  height: 160,
                  border: "1px solid #000",
                  borderRadius: "10px",
                  overflow: "auto",
                }}
              >
                <SelectedLocationsViewer selectedLocations={selectedStates} />
              </div>
            </Col>
          </Row>

          <div className="mt-4">
            <LeagueInput
              lable="Internal Route"
              value={adWithinAppRouting}
              setInput={setAdWithinAppRouting}
            />
            <LeagueInput
              lable="External Route"
              value={adOutsideAppRouting}
              setInput={setAdOutsideAppRouting}
            />
            <LeagueInput
              lable="Advertisement Type"
              value={adType}
              setInput={setAdType}
            />
            <p>Note: Ad OR Charity will be used as the advertisement type</p>
          </div>
        </Col>
      </Row>
      <div
        style={{
          alignItems: "flex-end",
          justifyContent: "flex-end",
          display: "flex",
          margin: "1vw",
          gap: "10px",
        }}
      >
       {

!isCreateCard ? <Button
variant="danger"
onClick={() => {
  deleteCard(id);
}}
>
Delete
</Button> :   <Button
            variant="secondary"
            onClick={handleshowModalClose}
            disabled={isSaving}
          >
            Close
          </Button>
       } 
        {isCreateCard ? (
          <Button variant="primary" onClick={createAdv} disabled={isSaving}>
            {isSaving ? "Creating..." : "Create"}
          </Button>
        ) : (
          <Button variant="primary" onClick={updateAdv} disabled={isSaving}>
            {isSaving ? "Saving..." : "Update"}
          </Button>
        )}
      </div>
    </div>
  );
};

const AdModalCard = ({
  id,
  title,
  previewImage,
  isActive,
  adImage,
  regionalPriority,
  setRegionalPriority,
  adLink,
  setAdLink,
  adRegion,
  setAdRegion,
  availableLocations,
  selectedLocations,
  adWithinAppRouting,
  setAdWithinAppRouting,
  adOutsideAppRouting,
  setAdOutsideAppRouting,
  adType,
  setAdType,
  states,
  setSelectedStates,
  handleSelectionChange,
  handleFileChange,
  isEditMode,
  isSaving,
  createAdv,
  updateAdv,
  deleteCard,
}) => {
  return (
    <div
      style={{
        borderRadius: "10px",
        border: "1px solid #000",
        margin: "1.5vw 1vw",
      }}
    >
      <Row style={{ padding: 25 }}>
        <Col md={6} style={{ textAlign: "left" }}>
          <Row>
            <h5 style={{ textAlign: "center" }}>{title}</h5>
          </Row>
          <Row>
            <p>Preview</p>
            <Col xs={6}>
              <img
                src={adImage}
                style={{
                  width: "100%",
                  height: 180,
                  resize: "both",
                  borderRadius: 10,
                }}
              />
            </Col>
            <Col md={5}>
              <h6>Active/Show In App</h6>
              <Radio
                checked={isActive}
                label="Solid"
                variant="solid"
                onClick={() => {}}
                color="warning"
                style={{ backgroundColor: "white" }}
              />
              <LeagueInput
                lable="Order"
                value={regionalPriority}
                setInput={setRegionalPriority}
                type="number"
              />
            </Col>
          </Row>

          <div>
            <Form.Group controlId="formFile" className="mt-4">
              <Form.Label>Image Upload</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={handleFileChange}
              />
            </Form.Group>
          </div>
          <LeagueInput lable="Link" value={adLink} setInput={setAdLink} />
          <LeagueInput lable="Region" value={adRegion} setInput={setAdRegion} />
        </Col>

        <Col md={6} style={{ textAlign: "left" }}>
          <Row>
            <h5 style={{ textAlign: "center" }}>Location Exclusions</h5>
          </Row>
          <Row>
            <Col xs={5}>
              <h6>Available</h6>
              <div
                style={{
                  height: 160,
                  border: "1px solid #000",
                  borderRadius: "10px",
                  overflow: "auto",
                }}
              >
                <CheckboxSelection
                  states={states}
                  onSelectionChange={handleSelectionChange}
                />
              </div>
            </Col>
            <Col
              xs={1}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: 160,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 5,
                }}
              >
                <Row
                  style={{
                    border: "1px solid #718EBF",
                    borderRadius: "10px",
                    height: 44,
                    width: 44,
                    alignItems: "center",
                  }}
                >
                  <BsArrowRight size={20} color="#718EBF" />
                </Row>
                <Row
                  style={{
                    border: "1px solid #718EBF",
                    borderRadius: "10px",
                    height: 44,
                    width: 44,
                    alignItems: "center",
                  }}
                >
                  <BsArrowLeft size={20} color="#718EBF" />
                </Row>
              </div>
            </Col>
            <Col xs={5}>
              <h6>Selected</h6>
              <div
                style={{
                  height: 160,
                  border: "1px solid #000",
                  borderRadius: "10px",
                  overflow: "auto",
                }}
              >
                <SelectedLocationsViewer
                  selectedLocations={selectedLocations}
                />
              </div>
            </Col>
          </Row>

          <div className="mt-4">
            <LeagueInput
              lable="Internal Route"
              value={adWithinAppRouting}
              setInput={setAdWithinAppRouting}
            />
            <LeagueInput
              lable="External Route"
              value={adOutsideAppRouting}
              setInput={setAdOutsideAppRouting}
            />
            <LeagueInput
              lable="Advertisement Type"
              value={adType}
              setInput={setAdType}
            />
            <p>Note: Ad OR Charity will be used as the advertisement type</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const SelectedLocationsViewer = ({ selectedLocations }) => {
  return (
    <div
      style={{
        height: "160px", // Set height for the scrollable container
        // border: '1px solid #ddd',
        // borderRadius: '8px',
        // overflowY: 'auto',
        padding: "10px",
        backgroundColor: "#f9f9f9",
      }}
    >
      {selectedLocations.length > 0 ? (
        <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
          {selectedLocations.map((location, index) => (
            <li
              key={index}
              style={{
                padding: "5px 10px",
                marginBottom: "5px",
                borderRadius: "4px",
                backgroundColor: "#ffffff",
                border: "1px solid #ccc",
              }}
            >
              {location}
            </li>
          ))}
        </ul>
      ) : (
        <p style={{ textAlign: "center", color: "#888" }}>
          No locations selected
        </p>
      )}
    </div>
  );
};
